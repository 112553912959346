import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import bannerImage1 from "./../../assets/homepage-banner-bg-1.jpg";
import Input from "./../common/forms/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SET_RESPONSE, CLEAR_RESPONSE } from "../../redux/actions/types";
import opacityImage from "./../../assets/login/2.png";

import {
  otp_verification,
  otp_resend,
  AUTH_OTP_RESEND,
  AUTH_OTP_VERIFY,
  SET_USER,
} from "../../redux/actions/auth.action";
import LoaderUI from "../common/Loader";
import AlertBox from "../common/AlertBox";
import { APP_LOGO } from "../../helper/assets";
import { Button } from "../Button/Button";

function OTPPage() {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [otpShortLenght, setOtpShortLenght] = useState(false);
  const [loaderResendOTP, setLoaderResendOTP] = useState(false);
  const [formData, setFormData] = useState({
    otp_code: "",
  });

  const auth = useSelector((store) => store.auth);

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const otpVerification = (event) => {
    event.preventDefault();

    console.log("formData.otp_code.length", formData.otp_code.length);
    if (formData.otp_code.length < 6) {
      setOtpShortLenght(true);
      return false;
    } else {
      setOtpShortLenght(false);
    }
    dispatch({ type: AUTH_OTP_VERIFY, payload: formData });
  };

  const regenerateOTP = (event) => {
    dispatch({ type: AUTH_OTP_RESEND, payload: {} });
  };

  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      <div
        id="page-login"
        className="h-screen root-page-bg-style"
        style={{
          backgroundImage: `url(${bannerImage1})`,
          backgroundSize: `contain`,
          backgroundPosition: `right center`,
        }}
      >
        <div className="flex h-full py-12 md:py-32  max-w-screen-xl  mx-auto px-4">
          <div className="flex bg-[#F6F6F6] ">
            <div className="w-1/2 lg:block hidden relative">
              <img
                src={opacityImage}
                alt=""
                className="absolute w-full h-full top-0 left-0"
              />

              <div className="hidden relative lg:flex flex-col items-center text-center mt-20">
                <Link to="/">{APP_LOGO}</Link>
                <h1 className="text-2xl sm:text-3xl md:text-4xl  mb-8 leading-tight mt-5">
                  Please check your e mail.
                </h1>
              </div>
            </div>
            <div className="w-full lg:w-1/2 bg-[#F6F6F6]  p-10 flex flex-col lg:justify-center overflow-auto">
              {!auth.loaderResendOTP ? (
                <form className="w-full " onSubmit={otpVerification}>
                  <div className=" lg:hidden relative flex flex-col items-center text-center">
                    <Link to="/">{APP_LOGO}</Link>
                    <h1 className="text-2xl sm:text-3xl md:text-4xl  mb-8 leading-tight mt-5">
                      Please check your e mail.
                    </h1>
                  </div>
                  <p className="text-xl mb-3 text-center">
                    A verification code was send to you email. Please enter that
                    code below or click the link in the email to finish setting
                    up your account.
                  </p>
                  <div className="flex gap-3 w-full">
                    <div className="w-full left flex flex-col gap-3">
                      <AlertBox />
                      <Input
                        type="text"
                        className="rounded-md"
                        placeholder="enter code"
                        value={formData.otp_code}
                        name="otp_code"
                        required
                        disabled={auth.loading ? "disabled" : ""}
                        onChange={handleInputChanged}
                        icon='<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.49933 13.1666H10.4993H11.7493V12.5V10.5V9.66665H10.4993H9.49933H8.24935V10.95V12V13.1666H9.49933ZM9.99935 23.6666C7.29657 22.9861 5.06552 21.4352 3.30618 19.014C1.54607 16.5935 0.666016 13.9055 0.666016 10.95V3.83331L9.99935 0.333313L19.3327 3.83331V10.95C19.3327 13.9055 18.453 16.5935 16.6937 19.014C14.9336 21.4352 12.7021 22.9861 9.99935 23.6666ZM9.99935 21.2166C12.0216 20.575 13.6938 19.2916 15.016 17.3666C16.3382 15.4416 16.9993 13.3028 16.9993 10.95V5.43748L9.99935 2.81248L2.99935 5.43748V10.95C2.99935 13.3028 3.66046 15.4416 4.98268 17.3666C6.3049 19.2916 7.97713 20.575 9.99935 21.2166Z" fill="#6622CC"/>
                              </svg>'
                        label="Enter Code"
                      />
                      {/* </div> */}
                      {otpShortLenght && (
                        <small className="wfull text-center text-danger">
                          Wait! OTP Pin should be of 6 digit.{" "}
                        </small>
                      )}
                      <p className=" text-center">
                        Don’t see the email? Wait a few minutes, or we can{" "}
                        <b
                          className="underline cursor-pointer"
                          onClick={regenerateOTP}
                        >
                          resend the code
                        </b>
                        .
                      </p>
                    </div>
                  </div>
                  <div className="w-full text-center flex justify-end mt-8">
                    {/* <button
                      type="submit"
                      className="bg-primary p-3 text-white rounded-md w-1/4 float-right flex gap-2 items-center justify-center"
                    >
                      {auth.loading ? (
                        <LoaderUI />
                      ) : (
                        <div className="flex  gap-2 items-center justify-center">
                          <span className="font-bold">continue</span>
                          <svg
                            width="8"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      )}
                    </button> */}
                    <Button
                      isLoading={auth.loading}
                      type="submit"
                      className="mt-5"
                    >
                      <div className="flex  gap-2 items-center justify-center">
                        <span className="font-bold">continue</span>
                        <svg
                          width="8"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 13.7143L5.71428 8L0 2.28571L1.14286 0L9.14286 8L1.14286 16L0 13.7143Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </Button>
                  </div>
                </form>
              ) : (
                <div className="w-full rounded-md bg-primary-light p-10">
                  <p className="text-white p-14 text-center text-xl">
                    Sending OTP Code again. Please wait...
                  </p>
                </div>
              )}
              <p className="text-normal text-center mb-3 mt-5">
                you can{" "}
                <span
                  className="text-primary cursor-pointer font-bold"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SET_USER, payload: null });
                    window.location.href =
                      process.env.REACT_APP_PATH + "/#/login";
                  }}
                >
                  login
                </span>{" "}
                or{" "}
                <span
                  className="text-primary cursor-pointer font-bold"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SET_USER, payload: null });
                    window.location.href =
                      process.env.REACT_APP_PATH + "/#/register";
                  }}
                >
                  signup
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="page-otp"
        className="h-screen root-page-bg-style"
        style={{
          backgroundImage: `url(${bannerImage1})`,
          backgroundSize: `contain`,
          backgroundPosition: `right center`,
        }}
      >
        <div className="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto p-4 h-screen">
          <div className="w-full flex justify-between flex-col main-page-group">
            <h1 className="text-6xl mb-3"></h1>
       

        

      
          </div>
        </div>
      </div> */}
    </>
  );
}

export default OTPPage;
