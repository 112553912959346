import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Parser from "html-react-parser";
import { TEMP_PP } from "../../../helper/assets";
import LoaderUI from "../../common/Loader";
import { USER_UPDATE_PROFILE_PIC_INFO } from "../../../redux/actions/user.action";
import { BlobServiceClient } from "@azure/storage-blob";

function ProfilePictureChange() {
  const account = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
  const accountKey = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_KEY;
  const sas = process.env.REACT_APP_AZURE_STORAGE_SAS;
  const containerName = process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME;

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);

  const [userUploadedPic, setUserUploadedPic] = useState(
    user.pp ? user.pp : TEMP_PP
  );

  const [formData, setFormData] = useState({
    profile_image: null,
  });

  const handleInputChange = async (event) => {
    let files = event.target.files;
    let file = files[0];
    // const blobName = file.name;
    // const blobServiceClient = new BlobServiceClient(
    //   `https://${account}.blob.core.windows.net${sas}`
    // );
    // const containerClient = blobServiceClient.getContainerClient(containerName);
    // const blobClient = containerClient.getBlobClient(blobName);
    // const blockBlobClient = blobClient.getBlockBlobClient();
    // const result = await blockBlobClient.uploadData(file, {
    //   blockSize: 4 * 1024 * 1024,
    //   concurrency: 20,
    //   onProgress: (ev) => console.log("ev", ev),
    // });
    //setUserUploadedPic(blockBlobClient.url);
    uploadNewPic(file);
  };

  const uploadNewPic = (newPP) => {
    formData.profile_image = newPP;
    console.log("formData", formData);
    dispatch({ type: USER_UPDATE_PROFILE_PIC_INFO, payload: formData });
  };

  useEffect(
    () => {},
    [
      /* dispatch */
    ]
  );

  return (
    <>
      <div className="avatar-pp-editor">
        <div className="profile-avatar">
          <img src={userUploadedPic} alt={user.legal_name} />
        </div>
        <div className="flex justify-center ">
          <button
            className="btn btn-primary image-upload-button"
            disabled={disabled ? "disabled" : ""}
          >
            {loader ? (
              <LoaderUI />
            ) : (
              <div className="flex gap-2 items-center justify-center">
                <input
                  type="file"
                  className="form-control"
                  name="upload_file"
                  onChange={handleInputChange}
                />
                <span style={{ whiteSpace: "nowrap" }}>Edit Photo</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
export default ProfilePictureChange;
