import http from "./http";

const settings_user_profile_general = async (payload) => {
  try {
    console.log("payload", payload);
    const formData = new FormData();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        formData.append(key, payload[key]);
      }
    }
    const x = await http.put("/user/profile", formData, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

const settings_security_details = async (payload) => {
  try {
    const x = await http.post("/user/update-password", payload, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

const settings_address_details = async (payload) => {
  try {
    const x = await http.post("/user/update-address", payload, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

const create_stripe_session = async (payload) => {
  try {
    const x = await http.post("/user/create-verification-session", payload, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

const user_profile_verification = async (payload) => {
  const formData = new FormData();
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      formData.append(key, payload[key]);
    }
  }

  try {
    const x = await http.post("/user/profile-verification", formData, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

const profile_verification_stripe_status = async (payload) => {
  try {
    const x = await http.get(
      "/user/profile-verification-stripe-status/" + payload.id,
      { headers: { "x-access-token": localStorage.getItem("token") } }
    );
    return x;
  } catch (error) {
    return error.response;
  }
};

const profile_verified_stripe = async (payload) => {
  try {
    const x = await http.get("/user/profile-verified-stripe/" + payload.id, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

const get_profile_update = async () => {
  try {
    const x = await http.get("/user/profile-stripe-update", {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    return x;
  } catch (error) {
    return error.response;
  }
};

export default {
  settings_user_profile_general,
  settings_security_details,
  settings_address_details,
  create_stripe_session,
  profile_verified_stripe,
  user_profile_verification,
  profile_verification_stripe_status,
  get_profile_update,
};
