// AddressValidationForm.js
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';

const searchOptions = {
    componentRestrictions: { country: "us" },
    /* location: new google.maps.LatLng(46.4, -96.0),
    radius: 10000,
    types: ['address'],
    region: "US-MN" */
}
const AddressValidationForm = (props) => {
  const [autoCompleteAddress, setAutoCompleteAddress] = useState('');


  const onChangeFunc = (address) => {
      setAutoCompleteAddress(address);
      if(!address) {
        if(props.cb) props.cb({});
      }
  }
  const onSelectFunc = (address) => {
        console.log('address',address)
        setAutoCompleteAddress(address)
        geocodeByAddress(address)
        .then(results => {
            console.log('results' , results)
            var place = results[0];
            let address1, 
            locality, 
            adminArea1Short, 
            adminArea1Long, 
            countryShort,
            countryLong, 
            postalCode = "";

            // Get each component of the address from the place details,
            for (const component of place.address_components) {
                
                const componentType = component.types[0];

                if (componentType === "street_number") {
                    address1 = component.long_name;
                }
                if (componentType === "route") {
                    address1 = `${address1} ${component.long_name}`;
                }
                if (componentType === "locality") {
                    locality = component.long_name;
                }
                if (componentType === "administrative_area_level_1") {
                    adminArea1Short = component.short_name;
                    adminArea1Long = component.long_name;
                }
                if (componentType === "postal_code") {
                    postalCode = component.long_name;
                }
                if (componentType === "postal_code_suffix") {
                    postalCode = `${postalCode}-${component.long_name}`;
                }
                if (componentType === "country") {
                    countryShort = component.short_name;
                    countryLong = component.long_name;
                }
            }

            let resAddress = {
                "address_full": address,
                "address1": address1,
                "locality": locality,
                "adminArea1Short": adminArea1Short,
                "adminArea1Long": adminArea1Long,
                "postalCode": postalCode,
                "countryShort": countryShort,
                "countryLong": countryLong
            };

            if(props.cb) props.cb(resAddress);

            return resAddress
        })
        .catch(error => console.error('Error', error));
  }

  useEffect(() => {
    console.log('props.address',props.address)
    if(props.address &&  props.address != ''){
      console.log('props.address', props.address)
      setAutoCompleteAddress(props.address);
    } 
  } , [props.address])

  return (
    <div>
      <PlacesAutocomplete
        value={autoCompleteAddress}
        onChange={onChangeFunc}
        onSelect={onSelectFunc}
        searchOptions={searchOptions}
      >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
              <input
              {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                  disabled: props.disabled ? props.disabled : false,
                  required: props.required ? props.required : false,
              })}
              />
              <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map( (suggestion, index) => {
                  const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                  <div
                  key={index }
                      {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      })}
                  >
                      <span>{suggestion.description}</span>
                  </div>
                  );
              })}
              </div>
          </div>
          )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressValidationForm;