import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import bannerImage1 from "./../../assets/homepage-banner-bg-1.jpg";
import RegisterForm from "../RegisterForm/RegisterForm";
import LoginForm from "../LoginForm/LoginForm";
import opacityImage from "./../../assets/login/2.png";
import { Button } from "../Button/Button";
import { APP_LOGO } from "../../helper/assets";
import RegisterConcentFormContent from "../common/RegisterConcentFormContent";

function RegisterPage() {
  const history = useHistory();
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);

  const showModalTermsFunc = (state) => {
    state
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
    setShowModalTerms(state);
  };
  const showModalPrivacyFunc = (state) => {
    state
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
    setShowModalPrivacy(state);
  };

  return (
    <>
      <div
        id="page-login"
        className="h-screen root-page-bg-style"
        style={{
          backgroundImage: `url(${bannerImage1})`,
          backgroundSize: `contain`,
          backgroundPosition: `right center`,
        }}
      >
        <div className="flex h-full py-12 md:py-32  max-w-screen-xl  mx-auto px-4">
          <div className="flex bg-[#F6F6F6] ">
            <div className="w-1/2 lg:block hidden relative">
              <img
                src={opacityImage}
                alt=""
                className="absolute w-full h-full top-0 left-0"
              />

              <div className="relative flex flex-col items-center text-center mt-20">
                <Link to="/">{APP_LOGO}</Link>
                <h1 className="text-5xl mb-8 leading-tight mt-5">
                  It’s as easy as one,
                  <span className="block"> two, three.</span>
                </h1>
              </div>
            </div>
            <div className="w-full lg:w-1/2 bg-[#F6F6F6] flex lg:items-center">
              <RegisterForm
                showModalTermsFunc={showModalTermsFunc}
                showModalPrivacyFunc={showModalPrivacyFunc}
              />
            </div>
          </div>
        </div>
      </div>

      {showModalTerms ? (
        <>
          <div className="tdo-modal-full">
            <div className="tdo-modal-header flex justify-between gap-2">
              <h3 className="text-3xl font-bold text-black">
                Terms & Condition
              </h3>
              <div
                className="close-btn cursor-pointer"
                onClick={() => showModalTermsFunc(false)}
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="4.41421"
                    y1="4.58579"
                    x2="20.4142"
                    y2="20.5858"
                    stroke="#6622CC"
                    stroke-width="4"
                  />
                  <line
                    x1="4.58579"
                    y1="20.5858"
                    x2="20.5858"
                    y2="4.58579"
                    stroke="#6622CC"
                    stroke-width="4"
                  />
                </svg>
              </div>
            </div>
            <div className="tdo-modal-content">
              <div className="tdo-modal-content-scroll">
                <RegisterConcentFormContent/>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showModalPrivacy ? (
        <>
          <div className="tdo-modal-full">
            <div className="tdo-modal-header flex justify-between gap-2">
              <h3 className="text-3xl font-bold text-black">Privacy Policy</h3>
              <div
                className="close-btn cursor-pointer"
                onClick={() => showModalPrivacyFunc(false)}
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="4.41421"
                    y1="4.58579"
                    x2="20.4142"
                    y2="20.5858"
                    stroke="#6622CC"
                    stroke-width="4"
                  />
                  <line
                    x1="4.58579"
                    y1="20.5858"
                    x2="20.5858"
                    y2="4.58579"
                    stroke="#6622CC"
                    stroke-width="4"
                  />
                </svg>
              </div>
            </div>
            <div className="tdo-modal-content">
              <div className="tdo-modal-content-scroll">
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
                <br />
                <b>Lorem ipsum dolor sit amet,</b>
                <p>
                  consectetur adipiscing elit. Mauris id nulla nibh. Donec
                  blandit justo eget diam vehicula, et convallis dolor molestie.
                  Quisque est nisl, lacinia fringilla sodales tristique,
                  sagittis aliquet turpis. Nam luctus nibh in metus posuere
                  tincidunt eu venenatis enim. Praesent suscipit ac tortor ac
                  interdum. Donec magna nisl, elementum ut ultrices ut, pretium
                  non nisi. Fusce efficitur sapien sit amet aliquet interdum.
                  Cras euismod, nunc vitae malesuada varius, mauris dui
                  tristique eros, a dapibus enim lectus ac felis. Aliquam
                  rhoncus dictum risus sit amet dictum. Suspendisse lobortis
                  elit vel felis ultrices ultricies. Duis vel elit sed quam
                  tempus ullamcorper a vel lorem. Curabitur congue sapien nec
                  libero vestibulum porttitor. Curabitur finibus non sapien
                  imperdiet congue. In feugiat nulla vitae massa iaculis
                  commodo.
                </p>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default RegisterPage;
