import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "./../common/forms/input";
import { Link } from "react-router-dom";
import { AUTH_REGISTER } from "../../redux/actions/auth.action";
import LoaderUI from "../common/Loader";
import { Redirect, useHistory } from "react-router-dom";
import AlertBox from "./../common/AlertBox";
import { Button } from "../Button/Button";

import { FormControl, TextField } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import InputMask from "react-input-mask";
import AddressValidationForm from "../AccountsPage/components/AddressValidationForm";
import { APP_LOGO, CLOSE_BTN } from "../../helper/assets";
import RegisterConcentFormContent from "../common/RegisterConcentFormContent";

function RegisterForm({ showModalTermsFunc, showModalPrivacyFunc }) {
  // retrieve contract key from reducer
  /* const contractKey = useSelector(store => store.contract.contractKey); */
  const history = useHistory();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [passwordMatchFailed, setPasswordMatchFailed] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",

    address_full: "",
    address_line1: "",
    address_city: "",
    address_country: "",
    address_state: "",
    address_zip: "",
  });

  const auth = useSelector((store) => store.auth);

  const formRef = useRef(null);
  const [steps, setSteps] = useState("basic");
  const [showConcentBlock, setShowConcentBlock] = useState(false);
  const [iAggree, setIAggree] = useState(false);
  const [formSubmitClicked, setFormSubmitClicked] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const registerUser = (event) => {
    event.preventDefault();
    console.log("form submit");
    setFormSubmitClicked(true);
    if (formData.password != formData.confirm_password) {
      setSteps("basic");
      setPasswordMatchFailed(true);
      return false;
    } else {
      setPasswordMatchFailed(false);
    }
    
    if(formData.phone && formData.dob) {
      setFormSubmitClicked(false);
      setShowConcentBlock(true);
    }
    
  };

  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onChangePhoneNumber = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      ["phone"]: value,
    }));
  };

  return (
    <>
      <form className="w-full p-10 overflow-auto" onSubmit={registerUser}>
        <div className="lg:hidden relative flex flex-col items-center text-center">
          <Link to="/">{APP_LOGO}</Link>
          <h1 className="text-2xl sm:text-3xl md:text-4xl  mb-8 leading-tight mt-5">
            It’s as easy as one, two, three.
          </h1>
        </div>
        <p className="text-xs mb-3">
          Buy and sell with confidence. By continuing, you agree to our{" "}
          <u
            onClick={() => showModalTermsFunc(true)}
            className="text-primary cursor-pointer"
          >
            terms of service
          </u>{" "}
          {/* and have read our{" "}
          <u
            onClick={() => showModalPrivacyFunc(true)}
            className="text-primary cursor-pointer"
          >
            privacy statment
          </u> */}
          .
        </p>
        <AlertBox />
        {steps == "basic" ? (
          <>
            <Input
              type="text"
              value={formData.first_name}
              name="first_name"
              required
              disabled={auth.loading ? "disabled" : ""}
              onChange={handleInputChanged}
              className="rounded-md nromal-padding"
              placeholder="First name"
              label="First Name"
            />
            {formSubmitClicked && formData.first_name == '' && <small className="text-danger">First name is required</small>}
            <Input
              type="text"
              value={formData.last_name}
              name="last_name"
              required
              disabled={auth.loading ? "disabled" : ""}
              onChange={handleInputChanged}
              className="rounded-md nromal-padding"
              parentClassName="mt-2"
              placeholder="Last Name"
              label="Last Name"
            />
            {formSubmitClicked && formData.last_name == '' && <small className="text-danger">Last name is required</small>}
            <Input
              type="email"
              value={formData.email}
              name="email"
              required
              disabled={auth.loading ? "disabled" : ""}
              onChange={handleInputChanged}
              className="rounded-md "
              placeholder="Email *"
              label="Email address"
              icon='<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M14.21 11.761C14.21 12.601 14.449 12.936 15.074 12.936C16.467 12.936 17.354 11.161 17.354 8.209C17.354 3.697 14.066 1.537 9.961 1.537C5.738 1.537 1.897 4.369 1.897 9.721C1.897 14.833 5.257 17.617 10.417 17.617C12.169 17.617 13.345 17.425 15.144 16.825L15.53 18.432C13.754 19.009 11.856 19.176 10.393 19.176C3.625 19.176 0 15.456 0 9.72C0 3.936 4.201 0 9.985 0C16.009 0 19.2 3.6 19.2 8.016C19.2 11.76 18.025 14.616 14.329 14.616C12.648 14.616 11.545 13.944 11.401 12.455C10.969 14.111 9.817 14.616 8.256 14.616C6.168 14.616 4.416 13.007 4.416 9.768C4.416 6.504 5.953 4.488 8.713 4.488C10.177 4.488 11.089 5.064 11.495 5.976L12.192 4.704H14.208V11.761H14.21ZM11.259 8.593C11.259 7.274 10.274 6.721 9.458 6.721C8.57 6.721 7.587 7.44 7.587 9.553C7.587 11.233 8.331 12.169 9.458 12.169C10.25 12.169 11.259 11.665 11.259 10.273V8.593Z" fill="#76BD6D"/>
                    </svg>'
            />
            {formSubmitClicked && formData.email == '' && <small className="text-danger">Email is required</small>}
            <Input
              type="password"
              value={formData.password}
              required
              disabled={auth.loading ? "disabled" : ""}
              name="password"
              parentClassName="mt-3"
              onChange={handleInputChanged}
              className="rounded-md"
              placeholder="Password"
              icon='<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
              <path d="M9.09765 11.1236C8.74966 11.1249 8.41174 11.2405 8.13585 11.4526C7.85996 11.6647 7.66137 11.9616 7.5706 12.2975C7.47983 12.6335 7.50192 12.9899 7.63346 13.3121C7.765 13.6343 7.99872 13.9043 8.29868 14.0807V16.3461H9.91611V14.0807C10.1534 13.9404 10.3504 13.741 10.4878 13.5019C10.6253 13.2629 10.6985 12.9924 10.7005 12.7166V12.7166C10.7005 12.5066 10.6589 12.2987 10.5783 12.1047C10.4976 11.9108 10.3794 11.7348 10.2304 11.5867C10.0815 11.4386 9.90469 11.3215 9.71029 11.242C9.51588 11.1625 9.30768 11.1223 9.09765 11.1236V11.1236Z" stroke="#76BD6D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.0844 7.46484H3.10462C1.94227 7.46484 1 8.40711 1 9.56946V17.8954C1 19.0577 1.94227 20 3.10462 20H15.0844C16.2467 20 17.189 19.0577 17.189 17.8954V9.56946C17.189 8.40711 16.2467 7.46484 15.0844 7.46484Z" stroke="#76BD6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.9082 7.46487V6.19333C3.9082 4.81598 4.45536 3.49503 5.4293 2.52109C6.40323 1.54715 7.72418 1 9.10154 1C10.4789 1 11.7998 1.54715 12.7738 2.52109C13.7477 3.49503 14.2949 4.81598 14.2949 6.19333V7.46487" stroke="#76BD6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              '
            />
            {formSubmitClicked && formData.password == '' && <small className="text-danger">Password is required</small>}
            <Input
              type="password"
              value={formData.confirm_password}
              required
              disabled={auth.loading ? "disabled" : ""}
              name="confirm_password"
              onChange={handleInputChanged}
              parentClassName="mt-3"
              className="rounded-md "
              placeholder="Re-enter Password"
              icon='<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
              <path d="M9.09765 11.1236C8.74966 11.1249 8.41174 11.2405 8.13585 11.4526C7.85996 11.6647 7.66137 11.9616 7.5706 12.2975C7.47983 12.6335 7.50192 12.9899 7.63346 13.3121C7.765 13.6343 7.99872 13.9043 8.29868 14.0807V16.3461H9.91611V14.0807C10.1534 13.9404 10.3504 13.741 10.4878 13.5019C10.6253 13.2629 10.6985 12.9924 10.7005 12.7166V12.7166C10.7005 12.5066 10.6589 12.2987 10.5783 12.1047C10.4976 11.9108 10.3794 11.7348 10.2304 11.5867C10.0815 11.4386 9.90469 11.3215 9.71029 11.242C9.51588 11.1625 9.30768 11.1223 9.09765 11.1236V11.1236Z" stroke="#76BD6D" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.0844 7.46484H3.10462C1.94227 7.46484 1 8.40711 1 9.56946V17.8954C1 19.0577 1.94227 20 3.10462 20H15.0844C16.2467 20 17.189 19.0577 17.189 17.8954V9.56946C17.189 8.40711 16.2467 7.46484 15.0844 7.46484Z" stroke="#76BD6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.9082 7.46487V6.19333C3.9082 4.81598 4.45536 3.49503 5.4293 2.52109C6.40323 1.54715 7.72418 1 9.10154 1C10.4789 1 11.7998 1.54715 12.7738 2.52109C13.7477 3.49503 14.2949 4.81598 14.2949 6.19333V7.46487" stroke="#76BD6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              '
            />
            {passwordMatchFailed && (
              <small className="text-danger">
                Password and confirm password does not match. please type again.{" "}
              </small>
            )}

            {/* <small className="text-primary text-sm">Password must contain:</small> */}
            {/* <ul className="pass-instruction list-disc grid grid-cols-2 gap-y-0 gap-x-2">
              <li className="text-primary text-sm">at least 8 characters</li>
              <li className="text-primary text-sm">at least one number</li>
              <li className="text-primary text-sm">
                at least one upper case letter
              </li>
              <li className="text-primary text-sm">
                at least one special character. Allowed characters !@#$%^&*
              </li>
              <li className="text-primary text-sm">
                at least one lower case letter
              </li>
            </ul> */}
          </>
        ) : (
          <>
            <div className="w-full left flex flex-col gap-3">
              <div className="flex flex-col w-full mt-2 mb-2 matrialui-local">
                <label className="text-white">
                  Phone Number <span className="text-danger">*</span>
                </label>
                <FormControl id="disabled_click_on_flag" required>
                  <MuiPhoneNumber
                  
                    id="buyer_phone"
                    onlyCountries={["us"]}
                    defaultCountry={"us"}
                    onChange={onChangePhoneNumber}
                    variant="outlined"
                    label="Phone"
                    size="small"
                    value={formData.phone}
                    InputProps={{
                      disabled: auth.loading,
                      required: true,
                    }}
                  />
                </FormControl>
                {formSubmitClicked && formData.phone == '' && <small className="text-danger">Phone is required</small>}
              </div>

              <div className="flex flex-col w-full mb-2 matrialui-local">
                <label className="text-white">
                  Date of Birth <span className="text-danger">*</span>
                </label>
                <FormControl required disabled={auth.loading}>
                  <InputMask
                    disabled={auth.loading}
                    mask="99 99 9999"
                    id="dob"
                    label="Date of Birth (Day Month Year)"
                    size="small"
                    onChange={handleInputChanged}
                    variant="outlined"
                    required={true}
                    value={formData.dob}
                    name="dob"
                  >
                    {(inputProps) => <TextField required {...inputProps} type="text" />}
                  </InputMask>
                </FormControl>
                {formSubmitClicked && formData.dob == '' && <small className="text-danger">Date of Birth is required</small>}
              </div>
            </div>
            <div className="w-full left flex flex-col gap-3">
              <div className="flex flex-col w-full mb-2 matrialui-local">
                <label className="text-white">
                  Type Address
                </label>
                <div className="flex flex-col w-full gap-2 mb-2 address_auto_complete">
                  <AddressValidationForm
                    address={formData.address_full}
                    disabled={auth.loading}
                    required={false}
                    cb={(address) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        ["address_full"]: address.address_full,
                        ["address_line1"]: address.address1,
                        ["address_country"]: address.countryLong,
                        ["address_state"]: address.countryLong,
                        ["address_zip"]: address.postalCode,
                        ["address_city"]: address.locality,
                      }));
                      setSelectedAddress({ ...address });
                    }}
                  />
                </div>

                <div className="flex flex-col w-full gap-2 mb-2 show-lables">
                  <FormControl>
                    <TextField
                      disabled={auth.loading}
                      required
                      id="address_line1"
                      label="Address 1"
                      size="small"
                      onChange={handleInputChanged}
                      variant="outlined"
                      value={formData.address_line1}
                      name="address_line1"
                    ></TextField>
                  </FormControl>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="flex flex-col w-full gap-2 mb-2 show-lables">
                    <FormControl>
                      <TextField
                        disabled={auth.loading}
                        required
                        id="address_city"
                        label="City"
                        size="small"
                        onChange={handleInputChanged}
                        variant="outlined"
                        value={formData.address_city}
                        name="address_city"
                      ></TextField>
                    </FormControl>
                  </div>

                  <div className="flex flex-col w-full gap-2 mb-2 show-lables">
                    <FormControl>
                      <TextField
                        disabled={auth.loading}
                        required
                        id="address_country"
                        label="Country"
                        size="small"
                        onChange={handleInputChanged}
                        variant="outlined"
                        value={formData.address_country}
                        name="address_country"
                      ></TextField>
                    </FormControl>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-3">
                  <div className="flex flex-col w-full gap-2 mb-2 show-lables">
                    <FormControl>
                      <TextField
                        disabled={auth.loading}
                        required
                        id="address_state"
                        label="State"
                        size="small"
                        onChange={handleInputChanged}
                        variant="outlined"
                        value={formData.address_state}
                        name="address_state"
                      ></TextField>
                    </FormControl>
                  </div>

                  <div className="flex flex-col w-full gap-2 mb-2 show-lables">
                    <FormControl>
                      <TextField
                        disabled={auth.loading}
                        required
                        id="address_zip"
                        label="Zip"
                        size="small"
                        onChange={handleInputChanged}
                        variant="outlined"
                        value={formData.address_zip}
                        name="address_zip"
                      ></TextField>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col gap-3">
          {steps == "basic" ? (
            <Button
              onClick={() => {
                setFormSubmitClicked(true);
                if (formData.password != formData.confirm_password) {
                  setSteps("basic");
                  setPasswordMatchFailed(true);
                  return;
                }
                setSteps("advance");
                setFormSubmitClicked(false);
              }}
              type="button"
              className="mt-5"
              disabled={submitDisabled ? "disabled" : ""}
            >
              Next Step for Sign Up
            </Button>
          ) : (
            <>
              <div className="flex flex gap-3">
                {!auth.loading && 
                  <Button
                    onClick={() => {
                      setSteps("basic");
                    }}
                    type="button"
                    className="mt-3 w-full"
                    disabled={submitDisabled ? "disabled" : ""}
                  >
                    Back
                  </Button>
                }
                <Button
                  type="submit"
                  isLoading={auth.loading}
                  className="mt-3 w-full"
                  disabled={submitDisabled ? "disabled" : ""}
                >
                  Sign Up
                </Button>
              </div>
            </>
          )}

          <Link to="/login" className="text-center">
            <u className="cursor-pointer">Sign In</u>
          </Link>
        </div>
      </form>

      {showConcentBlock && (
        <div className="concent-modal-box">
          <div className="concent-modal-box-area">
            <div
              className="close-modal"
              onClick={() => {
                setShowConcentBlock(false);
              }}
            >
              {CLOSE_BTN}
            </div>
            <div className="aggrement-area">
                  <RegisterConcentFormContent/>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                onChange={(e) => {
                  setIAggree(e.target.checked);
                }}
              />
              <label htmlFor="">I had read and agree to the Terms.</label>
            </div>
            <div className="actions">
              <div
                onClick={() => {
                  setShowConcentBlock(false);
                  dispatch({ type: AUTH_REGISTER, payload: formData });
                }}
                style={{ minWidth: "150px", cursor: "pointer" }}
                className={`${
                  !iAggree && "btn-disabled"
                } bg-primary p-3 text-white rounded-md w-1/3 flex  gap-2 items-center justify-center`}
              >
                <div className="flex  gap-2 items-center justify-center">
                  <span className="font-bold">Submit</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RegisterForm;
